<script setup>
import { computed, reactive, ref, watch } from 'vue'
import { useMutation, useQuery, useResult } from '@vue/apollo-composable'
import { GET_WORKSPACE_CUSTOMER, GET_WORKSPACE_SUBSCRIPTION } from '@/graphql/queries'
import { cloneDeep } from '@apollo/client/utilities'
import { error, formatUnixDate, stripTypename, success } from '@/utils'
import {
  CANCEL_SCHEDULE_UPDATE_WORKSPACE_SUBSCRIPTION,
  CANCEL_WORKSPACE_SUBSCRIPTION, SCHEDULE_UPDATE_WORKSPACE_SUBSCRIPTION,
  UPDATE_WORKSPACE_BY_ID,
  UPDATE_WORKSPACE_SUBSCRIPTION
} from '@/graphql/mutations'
import { InfoCircleOutlined, SaveOutlined } from '@ant-design/icons-vue'

const props = defineProps({
  workspaceId: String,
  workspace: Object
})

const emit = defineEmits(['update'])

const { onResult: onSubscriptionResult, refetch: refetchSubscription, loading: subscriptionLoading } = useQuery(GET_WORKSPACE_SUBSCRIPTION, { workspaceId: props.workspaceId }, { fetchPolicy: 'network-only' })
const { result: customerResult } = useQuery(GET_WORKSPACE_CUSTOMER, { workspaceId: props.workspaceId }, { fetchPolicy: 'network-only' })
const { mutate: updateWorkspaceSubscription, loading } = useMutation(UPDATE_WORKSPACE_SUBSCRIPTION)
const { mutate: cancelWorkspaceSubscription, cancelingWorkspaceSubscription } = useMutation(CANCEL_WORKSPACE_SUBSCRIPTION)
const { mutate: scheduleUpdateWorkspaceSubscription, updatingWorkspaceSubscriptionSchedule } = useMutation(SCHEDULE_UPDATE_WORKSPACE_SUBSCRIPTION)
const { mutate: cancelPendingUpdateWorkspaceSubscription, cancelingPendingWorkspaceSubscriptionSchedule } = useMutation(CANCEL_SCHEDULE_UPDATE_WORKSPACE_SUBSCRIPTION)
const { mutate: updateWorkspace, updatingWorkspace } = useMutation(UPDATE_WORKSPACE_BY_ID)

const updatingSubscription = computed(() => updatingWorkspace || updatingWorkspaceSubscriptionSchedule || cancelingPendingWorkspaceSubscriptionSchedule)

const DEFAULT_TRIAL_DAYS = 14
const DEFAULT_TRIAL_DEVICES_QUANTITY = 50

const trialState = reactive({
  trialDays: DEFAULT_TRIAL_DAYS,
  trialDevicesQuantity: DEFAULT_TRIAL_DEVICES_QUANTITY
})
const subscriptionDetails = ref(null)
const customerDetails = useResult(customerResult)
const stripeCustomPriceId = ref(props.workspace?.billingInfo?.stripeCustomPriceId)
const subscriptionState = reactive({})

const PAYMENT_METHOD_OPTIONS = [
  {
    label: 'Not Set',
    value: null
  },
  {
    label: 'Card',
    value: 'card'
  },
  {
    label: 'ACH Direct',
    value: 'ach_debit'
  },
  {
    label: 'Canadian Debit',
    value: 'acss_debit'
  },
  {
    label: 'Bank or Wire',
    value: 'us_bank_account'
  },
  {
    label: 'ACH credit',
    value: 'ach_credit_transfer'
  },
  {
    label: 'Check',
    value: 'paper_check'
  }
]

const COLLECTION_METHOD_OPTIONS = [
  {
    label: 'Automatically',
    value: 'charge_automatically'
  },
  {
    label: 'Invoice',
    value: 'send_invoice'
  }
]

onSubscriptionResult(({ data }) => {
  data?.getWorkspaceSubscription && mapSubscriptionToState(data?.getWorkspaceSubscription)
})

const mapSubscriptionToState = (subscriptionInfo) => {
  subscriptionDetails.value = subscriptionInfo
  setSubscriptionState()
}

const setSubscriptionState = () => {
  if (!subscriptionDetails.value) return
  const { billingItemsInfo, billingSettings } = cloneDeep(subscriptionDetails.value)
  subscriptionState.billingItemsInfo = billingItemsInfo ? stripTypename(billingItemsInfo) : null
  subscriptionState.billingSettings = billingSettings ? stripTypename(billingSettings) : null
}

const updateSubscription = (input) => {
  if (loading.value) return
  updateWorkspaceSubscription({
    workspaceId: props.workspaceId,
    input
  }).then(({ data }) => {
    data?.updateWorkspaceSubscription && mapSubscriptionToState(data?.updateWorkspaceSubscription)
  }).catch(e => {
    error(e.message)
  })
}

const handleWorkspaceSubscriptionUpdate = () => {
  updateSubscription({
    ...(subscriptionState.billingItemsInfo ? { billingItemsInfo: subscriptionState.billingItemsInfo } : null),
    ...(subscriptionState.billingSettings ? { billingSettings: subscriptionState.billingSettings } : null)
  })
}

const moveToEDUStarterTier = () => {
  updateSubscription({
    educationStarterItemsInfo: {
      starterDevicesQuantity: 1
    }
  })
}
const moveToEDUGrowthTier = () => {
  updateSubscription({
    billingItemsInfo: {
      tierGroup: 'education'
    }
  })
}
const moveToBusinessGrowthTier = () => {
  updateSubscription({
    billingItemsInfo: {
      tierGroup: 'business'
    }
  })
}
const handleAddTrial = () => {
  if (loading.value) return
  updateWorkspaceSubscription({
    workspaceId: props.workspaceId,
    input: {
      businessTrialItemsInfo: {
        trialDays: trialState.trialDays,
        trialDevicesQuantity: trialState.trialDevicesQuantity
      }
    }
  }).then(({ data }) => {
    data?.updateWorkspaceSubscription && mapSubscriptionToState(data?.updateWorkspaceSubscription)
    emit('update')
    success('Trial added')
  }).catch(e => {
    error(e.message)
  })
}

const changeStripeCustomPriceId = (now) => {
  const priceId = stripeCustomPriceId.value
  const currentDeviceQty = props.workspace?.billingInfo?.billingDevicesQuantity
  let action
  let payload
  if (now && !priceId) return
  if (currentDeviceQty === 0) {
    action = updateWorkspace
    payload = {
      id: props.workspaceId,
      input: {
        billingInfo: {
          stripeCustomPriceId: priceId
        }
      }
    }
  } else if (now) {
    action = updateWorkspaceSubscription
    payload = {
      workspaceId: props.workspaceId,
      input: {
        billingItemsInfo: {
          billingDevicePriceId: priceId,
          billingDevicesQuantity: currentDeviceQty
        }
      }
    }
  } else {
    action = priceId ? scheduleUpdateWorkspaceSubscription : cancelPendingUpdateWorkspaceSubscription
    const input = {
      billingItemsInfo: {
        billingDevicePriceId: priceId,
        billingDevicesQuantity: currentDeviceQty
      }
    }
    payload = {
      workspaceId: props.workspaceId,
      ...(priceId ? { input } : null)
    }
  }
  action(payload).then(() => {
    emit('update')
    success()
  }).catch(e => {
    error(e.message)
  })
}


const onCancelWorkspaceSubscription = () => {
  cancelWorkspaceSubscription({ workspaceId: workspaceId.value }).then(() => {
    refetchSubscription()
  })
}

watch(()=> props.workspace, (newVal) => {
  stripeCustomPriceId.value = newVal?.billingInfo?.stripeCustomPriceId
})

</script>

<template>
  <div style="height: 100%; padding-left: 16px; padding-bottom: 100px;">
    <a-spin
      :spinning="subscriptionLoading"
      style="width: 100%; min-height: 200px;"
    >
      <div
        v-if="!subscriptionLoading"
      >
        <div style="padding-bottom: 30px;">
          <a-descriptions
            title="Subscription Info"
            layout="horizontal"
            size="small"
            bordered
          >
            <a-descriptions-item
              label="Created"
            >
              {{ formatUnixDate(subscriptionDetails?.created) }}
            </a-descriptions-item>
            <a-descriptions-item
              label="Period Start"
            >
              {{ formatUnixDate(subscriptionDetails?.current_period_start) }}
            </a-descriptions-item>
            <a-descriptions-item
              label="Period End"
            >
              {{ formatUnixDate(subscriptionDetails?.current_period_end) }}
            </a-descriptions-item>
            <a-descriptions-item
              label="Device qty"
            >
              <a-input-number
                v-if="subscriptionState?.billingItemsInfo"
                v-model:value="subscriptionState.billingItemsInfo.billingDevicesQuantity"
                :min="1"
                :max="10000"
                size="small"
                :disabled="loading"
                @press-enter="handleWorkspaceSubscriptionUpdate"
              />
            </a-descriptions-item>
            <a-descriptions-item
              label="Billing Cycle"
            >
              {{ formatUnixDate(subscriptionDetails?.billing_cycle_anchor) }}
            </a-descriptions-item>
            <a-descriptions-item
              label="Next Invoice"
            >
              {{ formatUnixDate(subscriptionDetails?.next_pending_invoice_item_invoice) || 'None' }}
            </a-descriptions-item>
            <a-descriptions-item
              label="Started At"
            >
              {{ formatUnixDate(subscriptionDetails?.start_date) || 'None' }}
            </a-descriptions-item>
            <a-descriptions-item
              label="Ended At"
            >
              {{ formatUnixDate(subscriptionDetails?.ended_at) || 'None' }}
            </a-descriptions-item>
            <a-descriptions-item
              label="Canceled At"
            >
              {{ formatUnixDate(subscriptionDetails?.canceled_at) || 'None' }}
            </a-descriptions-item>
            <template v-if="subscriptionDetails?.discount">
              <a-descriptions-item
                label="Discount Name"
              >
                {{ subscriptionDetails?.discount?.coupon?.name }}
              </a-descriptions-item>
              <a-descriptions-item
                label="Discount End"
              >
                {{ subscriptionDetails?.discount?.end || 'Forever' }}
              </a-descriptions-item>
              <a-descriptions-item
                label="Amount"
              >
                <span v-if="subscriptionDetails?.discount?.coupon?.currency">
                  {{ subscriptionDetails?.discount?.coupon?.currency }}
                  {{ subscriptionDetails?.discount?.coupon?.amount_off }}
                </span>
                <span v-else>
                  {{ subscriptionDetails?.discount?.coupon?.percent_off }}%
                </span>
                <a-tooltip>
                  <template #title>
                    <JsonViewer
                      :value="subscriptionDetails?.discount"
                      copyable
                      preview-mode
                      sort
                      theme="jv-light"
                    />
                  </template>
                  <InfoCircleOutlined />
                </a-tooltip>
              </a-descriptions-item>
            </template>
            <template v-if="subscriptionState?.billingSettings">
              <a-descriptions-item
                label="Col. Method"
              >
                <a-select
                  v-if="subscriptionState.billingSettings"
                  v-model:value="subscriptionState.billingSettings.collectionMethod"
                  size="small"
                  multiple
                  :options="COLLECTION_METHOD_OPTIONS"
                />
              </a-descriptions-item>
              <a-descriptions-item
                label="Days to dew"
              >
                <a-input-number
                  v-if="subscriptionState?.billingSettings"
                  v-model:value="subscriptionState.billingSettings.daysUntilDue"
                  :min="1"
                  :max="10000"
                  size="small"
                  :disabled="loading || subscriptionState?.billingSettings?.collectionMethod === 'charge_automatically'"
                  @press-enter="handleWorkspaceSubscriptionUpdate"
                />
              </a-descriptions-item>
              <a-descriptions-item
                label="Payment Method"
              >
                <a-select
                  v-if="subscriptionState.billingSettings"
                  v-model:value="subscriptionState.billingSettings.paymentMethodTypes"
                  size="small"
                  multiple
                  :options="PAYMENT_METHOD_OPTIONS"
                />
              </a-descriptions-item>
            </template>
            <a-descriptions-item
              label="Balance"
            >
              ${{ customerDetails?.balance }}
            </a-descriptions-item>
            <a-descriptions-item
              label="Status"
            >
              {{ subscriptionDetails?.status }}
            </a-descriptions-item>
            <a-descriptions-item
              label="Price Id"
            >
              {{ subscriptionState.billingItemsInfo?.billingDevicePriceId }}
            </a-descriptions-item>
          </a-descriptions>
          <a-divider />
          <a-form>
            <div style="display: flex; align-items: center; gap: 8px;">
              <a-typography-text style="white-space: nowrap;">
                Set Custom Price Id:
              </a-typography-text>
              <a-input-group
                compact
                title="Set Custom Price Id"
              >
                <a-input
                  v-model:value="stripeCustomPriceId"
                  :disabled="updatingSubscription"
                  :min="0"
                  style="width: 200px;"
                  size="small"
                />
                <a-tooltip title="Save">
                  <a-popconfirm
                    title="Now or on renewal?"
                    ok-text="On renewal"
                    cancel-text="Now"
                    @cancel="changeStripeCustomPriceId(true)"
                    @confirm="changeStripeCustomPriceId()"
                  >
                    <a-button
                      type="primary"
                      size="small"
                      html-type="submit"
                      :disabled="updatingSubscription"
                    >
                      <template #icon>
                        <SaveOutlined />
                      </template>
                    </a-button>
                  </a-popconfirm>
                </a-tooltip>
              </a-input-group>
            </div>
          </a-form>
          <a-divider />
          <a-space>
            <a-popconfirm
              title="Are you sure?"
              @confirm="moveToEDUStarterTier"
            >
              <a-button
                type="primary"
                size="small"
                :disabled="workspace?.billingInfo?.tier !== 'business-trial'"
                :loading="loading"
              >
                EDU Starter
              </a-button>
            </a-popconfirm>
            <a-popconfirm
              title="Are you sure?"
              @confirm="moveToEDUGrowthTier"
            >
              <a-button
                type="primary"
                size="small"
                :disabled="workspace?.billingInfo?.tier !== 'business-growth'"
                :loading="loading"
              >
                EDU Growth
              </a-button>
            </a-popconfirm>
            <a-popconfirm
              title="Are you sure?"
              @confirm="moveToBusinessGrowthTier"
            >
              <a-button
                type="primary"
                size="small"
                :disabled="workspace?.billingInfo?.tier !== 'education-growth'"
                :loading="loading"
              >
                Business Growth
              </a-button>
            </a-popconfirm>
          </a-space>
          <a-divider />
          <a-descriptions
            layout="horizontal"
            size="small"
            title="Add Trial"
            style="width: 60%;"
            bordered
          >
            <a-descriptions-item
              label="Days:"
            >
              <a-input-number
                v-model:value="trialState.trialDays"
                :min="0"
                :max="50"
                size="small"
                :disabled="loading"
                @press-enter="handleAddTrial"
              />
            </a-descriptions-item>
            <a-descriptions-item
              label="Devices:"
            >
              <a-input-number
                v-model:value="trialState.trialDevicesQuantity"
                :min="1"
                :max="50"
                size="small"
                :disabled="loading"
                @press-enter="handleAddTrial"
              />
            </a-descriptions-item>
            <a-descriptions-item>
              <a-button
                type="primary"
                size="small"
                style="align-self: flex-end; width: 100px;"
                :loading="loading"
                @click="handleAddTrial"
              >
                Add Trial
              </a-button>
            </a-descriptions-item>
          </a-descriptions>
        </div>
        <div style="position: sticky; bottom: -24px; background-color: #fff; height: 50px; display: flex; align-items: center;">
          <a-space>
            <a
              :href="customerDetails?.stripeDashboardUrl"
              target="_blank"
            >Customer</a>
            <a-divider type="vertical" />
            <a
              :href="subscriptionDetails?.stripeDashboardUrl"
              target="_blank"
            >Subscription</a>
            <a-divider type="vertical" />
            <a-button
              size="small"
              danger
              :loading="cancelingWorkspaceSubscription"
              :disabled="!subscriptionDetails || subscriptionDetails?.status === 'canceled'"
              @click="onCancelWorkspaceSubscription"
            >
              Cancel Subscription
            </a-button>
          </a-space>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<style scoped>

</style>
